@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.standard-mt {
  @apply mt-16 md:mt-20 lg:mt-12 xl:mt-16 2xl:mt-24;
}
h1 {
  @apply font-roboto text-[2rem] font-bold md:text-[2.5rem] lg:text-[1.75rem] xl:text-[2rem] 2xl:text-[3.5rem];
}

h2 {
  @apply font-roboto text-[1.5rem] font-semibold md:text-[1.75rem] lg:text-[1rem] xl:text-[1.25rem] 2xl:text-[2rem];
}

h4 {
  @apply font-roboto text-[1rem] font-medium md:text-[1.25rem] lg:text-[0.875rem] xl:text-[1rem] 2xl:text-[1.5rem];
}

h5,
a,
input,
textarea,
Link {
  @apply font-roboto text-[0.9rem] md:text-[1rem] lg:text-[0.8rem] xl:text-[0.9rem] 2xl:text-[1.25rem];
}

h6 {
  @apply font-roboto text-[0.875rem] md:text-[1rem] lg:text-[0.75rem] xl:text-[0.875rem] 2xl:text-[1.1rem];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.shadowContainer {
  @apply shadow-[0_0_1rem_rgba(0,55,140,0.126)];
}
.containerHover {
  @apply transition duration-[0.15s] ease-in hover:-translate-y-3;
}

.extendLink {
  @apply [&>*:nth-child(1)]:mr-0 [&>*:nth-child(1)]:w-0 [&>*:nth-child(1)]:transition-all [&>*:nth-child(1)]:duration-300 [&>*:nth-child(1)]:ease-in-out [&>*:nth-child(1)]:hover:mr-2 [&>*:nth-child(1)]:hover:w-4;
}
.dashboardDropdown {
  @apply flex cursor-pointer items-center justify-between py-2  [&>*:nth-child(2)]:transition-transform  [&>*:nth-child(2)]:duration-300 [&>*:nth-child(2)]:ease-in-out;
}
.navLinkEffects {
  @apply cursor-pointer transition-colors duration-300 ease-in-out hover:text-[#9eabb8];
}
.leaflet-pane {
  z-index: 0 !important;
}
